export default {
  setEmpresa({ commit }, payload) {
    commit("SET_EMPRESA", payload);
  },
  setCorretora({ commit }, payload) {
    commit("SET_CORRETORA", payload);
  },
  setOperadora({ commit }, payload) {
    commit("SET_OPERADORA", payload);
  },
  setNrContrato({ commit }, payload) {
    commit('SET_NR_CONTRATO', payload);
  },
  setVigencia({ commit }, payload) {
    commit("SET_VIGENCIA", payload);
  },
  setFinanceiro({ commit }, payload) {
    commit("SET_FINANCEIRO", payload);
  },
  setGUID({ commit }, payload) {
    commit("SET_GUID", payload);
  },
  setID({ commit }, payload) {
    commit("SET_ID", payload);
  },
  resetDadosPME({ commit }, payload) {
    commit("RESET_DADOS_PME", payload);
  },
  setDadosPME({ commit }, payload) {
    commit("SET_DADOS_PME", payload);
  },
  setTitularPME({ commit }, payload) {
    commit("SET_TITULARPME", payload);
  },
  setPlano({ commit }, payload) {
    commit("SET_PLANO", payload);
  },
  setDependentes({ commit }, payload) {
    commit("SET_DEPENDENTES", payload);
  },
  setBtnEdit({ commit }, payload) {
    commit("SET_BTN_EDIT", payload)
  },
  setAdendo({ commit }, payload) {
    commit('SET_ADENDO', payload);
  },
  setPendencias({ commit }, payload) {
    commit('SET_PENDENCIAS', payload);
  },
  setEmpresaAssociada({ commit }, payload) {
    commit('SET_EMPRESA_ASSOCIADA', payload)
  }
};
