const state = {
  id: null,
  guid: null,
  financeiro: null,
  empresa: null,
  corretora: null,
  operadora: null,
  totalPropostas: 0,
  vigencia: null,
  titular: null,
  dependentes: [],
  planos: null,
  btnEdit: {}
};

export default state;
