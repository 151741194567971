const state = {
  id: null,
  guid: null,
  nrContrato: null,
  financeiro: null,
  empresa: null,
  corretora: null,
  operadora: null,
  totalPropostas: 0,
  vigencia: null,
  titular: null,
  dependentes: [],
  planos: null,
  btnEdit: {},
  adendo: [],
  pendencias: [],
  usuarioTratando: null
};

export default state;
