export default {
  LOGOUT(state) {
    state.proposta = {};
    state.dadosPessoais = {};
    state.corretora = {};
    state.plano = null;
    state.dataVigencia = null;
    state.titular = {};
    state.dependentes = [];
    state.pagamento = {};
    state.dadosParaReembolso = {};
    state.documentos = {};
    state.idLead = null;
    state.blnContinuar = {
      flag: false,
      page: 0,
      isEditing: false,
      isEditingPlan: false,
      isEditingRL: false,
      isEditingVigencia: false,
      planoNaoComercializado: false,
    };
    state.responsavelLegal = null;
    state.pendencias = [];
    state.adendo = [];
    state.produtosOpcionais = {};
    state.dadosComplementaresFiliacao = null;
    state.inicializada = null;
  },
  SET_ID_LEAD(state, payload) {
    state.idLead = payload;
  },
  SET_CORRETORA(state, payload) {
    state.corretora = payload.corretora;
  },
  SET_OPERADORA(state, payload) {
    state.plano = { ...(state.plano || {}), operadora: payload };
  },
  SET_PLANO(state, payload) {
    state.plano = { ...payload };
    state.valorTotalProposta = payload.valorTotal;
  },
  SET_VIGENCIA(state, payload) {
    state.dataVigencia = payload.dataVigencia;
    state.vigencia = { ...payload };
  },
  SET_DADOS_TITULAR(state, payload) {
    const titular = payload.titular || {};

    state.titular = {
      guid: titular.guid || payload.guid || '',
      cpf: payload.cpf || '',
      nome: payload.nome,
      dataNascimento: payload.dataNascimento,
      dataAdmissao: payload.dataAdmissao || null,
      matriculaFuncional: payload.matriculaFuncional,
      estadoCivil: payload.estadoCivil,
      email: payload.email || '',
      nomeMae: payload.nomeMae,
      sexo: payload.sexo,
      telefones: payload.telefones || [],
      enderecos: payload.enderecos || [],
      rg: payload.rg || '',
      rgOrgaoEmissor: payload.rgOrgaoEmissor || null,
      rgOrgaoEmissorUf: payload.rgOrgaoEmissorUf || null,
      rgDataExpedicao: payload.rgDataExpedicao || null,
      pisPasep: payload.pisPasep || '',
      entidade: payload.entidade,
      publicoAlvo: payload.publicoAlvo,
      possuiPlano: payload.possuiPlano,
      acomodacaoAnterior: payload.acomodacaoAnterior || '',
      operadoraAnterior: payload.operadoraAnterior || '',
      reducaoCarencia: payload.reducaoCarencia || null,
      codigoCarencia: payload.codigoCarencia || null,
      descricaoCodigoCarencia: payload.descricaoCodigoCarencia || null,
      tempoPlanoEmMesesAnterior: payload.tempoPlanoEmMesesAnterior || null,
      campoCarteirinha: payload.campoCarteirinha || null,
      aceitouNaoReduzirCarencia: payload.aceitouNaoReduzirCarencia || false,
      valor: payload.valor,
      valorSemDesconto: payload.valorSemDesconto,
      valorOdontoAdicional: payload.valorOdontoAdicional || null,
      produtosOpcionais: payload.produtosOpcionais,
      segmentacaoAnterior: payload.segmentacaoAnterior,
      portabilidade: payload.portabilidade,
      cns: payload.cns || null,
      declaracaoNascidoVivo: payload.declaracaoNascidoVivo || null,
      empossadoPensionista: payload.empossadoPensionista || null,
      //UNIMED-RIO - redução de carência
      registroAnsPlanoAnteriorReducaoCarencia: payload.registroAnsPlanoAnteriorReducaoCarencia || null,
      operadoraPlanoAnteriorReducaoCarencia: payload.operadoraPlanoAnteriorReducaoCarencia || null,
      tempoPlanoAnteriorReducaoCarencia: payload.tempoPlanoAnteriorReducaoCarencia || null,
      possuiPlanoAnteriorReducaoCarencia: payload.possuiPlanoAnteriorReducaoCarencia || null,
      //UNIMED-RIO - redução de carência
      //UNIMED-RIO - PORTABILIDADE      
      nomeOperadoraAnteriorPortabilidade: payload.nomeOperadoraAnteriorPortabilidade || null,
      registroAnsOperadoraAnteriorPortabilidade: payload.registroAnsOperadoraAnteriorPortabilidade || null,
      nomePlanoAnteriorPortabilidade: payload.nomePlanoAnteriorPortabilidade || null,
      registroAnsPlanoAnteriorPortabilidade: payload.registroAnsPlanoAnteriorPortabilidade || null,
      dataInicioPlanoAnteriorPortabilidade: payload.dataInicioPlanoAnteriorPortabilidade || null,
      ultimoPagamentoPlanoAnteriorPortabilidade: payload.ultimoPagamentoPlanoAnteriorPortabilidade || null,
      exerceuPortabilidade: payload.exerceuPortabilidade || null,
      cumpriuOuCumprindoCPTPortabilidade: payload.cumpriuOuCumprindoCPTPortabilidade || null
      //UNIMED-RIO - PORTABILIDADE      
    };
  },
  SET_DADOS_PROFISSIONAIS(state, payload) {
    state.titular.dadosProfissionais = payload;
  },
  SET_DADOS_FILIACAO(state, payload) {
    state.dadosComplementaresFiliacao = payload;
  },
  SET_DADOS_DEPENDENTES(state, payload) {
    state.dependentes = [...payload];
  },
  UPDATE_DEPENDENTE(state, payload) {
    const index = state.dependentes.findIndex((dep) => dep.id === payload.id);
    if (index === -1) return;
    state.dependentes[index] = { ...payload };
  },
  SET_DADOS_FINANCEIROS(state, payload) {
    state.pagamento = payload.dadosPagamento;
    state.dadosParaReembolso = payload.dadosParaReembolso;
  },
  SET_DADOS_PAGAMENTO(state, payload) {
    state.pagamento = payload && !payload.agencia && !payload.banco
      && !payload.codBanco && !payload.contaCorrente && !payload.forma ? null : payload;
  },
  SET_DADOS_REEMBOLSO(state, payload) {
    state.dadosParaReembolso = payload;
  },
  SET_DADOS_DOCUMENTOS(state, payload) {
    state.documentos = { ...payload };
  },
  SET_DADOS_PORTABILIDADE(state, payload) {
    state.possuiPortabilidade = payload;
  },
  SET_DADOS_SUMULA(state, payload) {
    state.possuiSumula = payload.possuiSumula;
    state.sumulaPlanoAnterior = payload.sumulaPlanoAnterior;
  },
  SET_DADOS_ADMINISTRADORA(state, payload) {
    state.administradora = payload;
  },
  SET_DADOS_PROSPOSTA(state, payload) {
    state.proposta = {
      ...payload,
      administradora: state.administradora,
      possuiPortabilidade: state.possuiPortabilidade,
      possuiSumula: state.possuiSumula,
      sumulaPlanoAnterior: state.sumulaPlanoAnterior,
      titular: state.titular,
      dependentes: state.dependentes,
      corretora: state.corretora && state.corretora.cpfCnpj ? state.corretora : null,
      plano: state.plano,
      dataVigencia: state.dataVigencia,
      pagamento: state.pagamento,
      dadosParaReembolso: state.dadosParaReembolso,
      responsavelLegal: state.responsavelLegal,
      valorTotalProposta: state.valorTotalProposta,
      tipoTaxaAngariacao: state.tipoTaxaAngariacao,
      valorTaxaAngariacao: state.valorTaxaAngariacao,
      idFilial: state.idFilial,
      produtosOpcionais: state.produtosOpcionais,
      dadosComplementaresFiliacao: state.dadosComplementaresFiliacao,
      corretoraRepasse: state.corretoraRepasse,
    };
  },
  RESET_DADOS_PROSPOSTA(state, payload) {
    state.administradora = null;
    state.possuiPortabilidade = null;
    state.titular = {};
    state.dependentes = [];
    state.corretora = {};
    state.plano = null;
    state.dataVigencia = '';
    state.dadosProfissionais = null;
    state.dadosComplementaresFiliacao = null;
    state.pagamento = null;
    state.dadosParaReembolso = null;
    state.responsavelLegal = null;
    state.blnContinuar = {
      flag: false,
      page: 0,
      isEditing: false,
      isEditingPlan: false,
      isEditingRL: false,
    };
    state.pendencias = [];
    state.adendo = [];
    state.idFilial = null;
    state.tipoTaxaAngariacao = null;
    state.valorTaxaAngariacao = null;
    state.valorTotalProposta = null;
    state.valorTaxaAdesao = null;
    state.proposta = {};
    state.produtosOpcionais = [];
    state.corretoraRepasse = null;
    state.inicializada = null;
    if (localStorage.getItem('etapaBeforeReset')) {
      localStorage.removeItem('etapaBeforeReset');
    }
  },
  RESET_DADOS_PLANO(state, payload) {
    state.produtosOpcionais = [];
    state.plano = null;
    state.plano = { operadora: payload.operadora };
    state.etapaBeforeReset = state.proposta.etapa;
    localStorage.setItem('etapaBeforeReset', state.etapaBeforeReset);

    if (state.dependentes.length) {
      const { dependentes } = state;
      for (let index = 0; index < dependentes.length; index++) {
        dependentes[index].parentesco = "";
      }
    }
    if (state.responsavelLegal && state.responsavelLegal.guid) {
      const { responsavelLegal } = state;
      responsavelLegal.parentesco = "";
    }
    state.proposta.dependentes = state.dependentes;
    state.proposta.responsavelLegal = state.responsavelLegal;
  },
  SET_CONTINUAR_DADOS(state, payload) {
    state.blnContinuar = {
      flag: payload.flag,
      page: payload.page,
      isEditing: payload.isEditing,
      isEditingPlan: payload.isEditingPlan,
      isEditingRL: payload.isEditingRL,
      isEditingVigencia: payload.isEditingVigencia,
      isEditingCPFTitular: payload.isEditingCPFTitular,
      planoNaoComercializado: payload.planoNaoComercializado,
    };
  },
  SET_RESPONSAVEL_LEGAL(state, payload) {
    state.responsavelLegal = payload;
  },
  SET_PENDENCIAS(state, payload) {
    state.pendencias = payload;
  },
  SET_ADENDO(state, payload) {
    state.adendo = payload;
  },
  SET_TIPO_TAXA_ANGARIACAO(state, payload) {
    state.tipoTaxaAngariacao = payload;
  },
  SET_VALOR_TAXA_ANGARIACAO(state, payload) {
    state.valorTaxaAngariacao = payload;
  },
  SET_JUSTIFICATIVA_TAXA(state, payload) {
    state.justificativaTaxaAngariacao = payload;
  },
  SET_ID_FILIAL(state, payload) {
    state.idFilial = payload;
  },
  SET_PRODUTOS_OPCIONAIS(state, payload) {
    state.produtosOpcionais = payload;
  },
  SET_CORRETORA_REPASSE(state, payload) {
    state.corretoraRepasse = payload;
  },
  SET_ENTIDADE_ALTERADA(state, payload) {
    state.entidadeAlterada = payload;
  },
};
