export default {
  SET_EMPRESA(state, payload) {
    state.empresa = {
      ...payload,
    };
  },
  SET_CORRETORA(state, payload) {
    state.corretora = {
      ...payload,
    };
  },
  SET_OPERADORA(state, payload) {
    state.operadora = {
      ...payload,
    };
  },
  SET_TITULARPME(state, payload) {
    state.titular = {
      ...payload,
    };
  },
  SET_VIGENCIA(state, payload) {
    state.vigencia = payload.vigencia
    state.dataFechamentoReapresentacao = payload.dataFechamentoReapresentacao
    state.dataFechamentoAceitacao = payload.dataFechamentoAceitacao
    state.tempoParaTransmissao = payload.tempoParaTransmissao
  },
  SET_FINANCEIRO(state, payload) {
    state.financeiro = payload
  },
  SET_GUID(state, payload) {
    state.guid = payload
  },
  SET_ID(state, payload) {
    state.id = payload
  },
  SET_NR_CONTRATO(state, payload) {
    state.nrContrato = payload;
  },
  SET_DEPENDENTES(state, payload) {
    state.dependentes = {
      ...payload
    }
  },
  SET_PLANO(state, payload) {
    state.plano = {
      ...payload
    }
  },
  RESET_DADOS_PME(state) {
    state.id = null
    state.guid= null
    state.nrContrato = null
    state.financeiro= null
    state.empresa= null
    state.corretora= null
    state.operadora= null
    state.totalPropostas= 0
    state.vigencia= null
    state.titular= null
    state.dependentes= []
    state.planos= null
    state.btnEdit= {}
    state.usuarioTratando = null
  },
  SET_DADOS_PME(state, payload) {
    state.guid = payload.guid;
    state.id = payload.id;
    state.nrContrato = payload.nrContrato;
    state.empresa = payload.empresa;
    state.etapa = payload.etapa;
    state.corretora = payload.corretora;
    state.vigencia = payload.vigencia;
    state.operadora = payload.operadora;
    state.financeiro = payload.financeiro;
    state.responsavel = payload.responsavel;
    state.totalPropostas = payload.totalPropostas;
    state.isEditing = payload.isEditing;
    state.etapaEditing = payload.etapaEditing;
  },
  SET_BTN_EDIT(state, payload) {
    state.btnEdit = {
      ...payload
    }
  },
  SET_ADENDO(state, payload) {
    state.adendo = payload;
  },
  SET_PENDENCIAS(state, payload) {
    state.pendencias = payload;
  },
  SET_EMPRESA_ASSOCIADA(state, payload) {
    state.usuarioTratando = payload.usuarioTratando
  }
};
