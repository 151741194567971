import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import cadastroCorretor from "@/store/modules/cadastroCorretor";
import user from "@/store/modules/user";
import cadastroProposta from "@/store/modules/cadastroProposta";
import simulacao from "@/store/modules/simulacao";
import resumoProposta from "@/store/modules/resumoProposta";
import lead from "@/store/modules/lead";
import PME from "@/store/modules/PME";
import notificacoes from "@/store/modules/notificacoes";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    cadastroCorretor,
    user,
    cadastroProposta,
    simulacao,
    resumoProposta,
    lead,
    PME,
    notificacoes,
  },
  plugins: [
    createPersistedState({
      key: "secure",
      paths: ["auth", "user"],
    }),
  ],
  state: {
    loadingInit: true,
    whitelabelConfig: {
      appName: "",
      logoClaro: "",
      logoEscuro: "",
      logoComTexto: true,
      colors: {
        primary: "",
        secundary: "",
        ternary: "",
        bgColor: "",
      },
      welcome: {
        titulo: "",
        descricao: "",
        saibaMais: "",
      },
    },
    filiacaoEntidadesPermitidas: ["ASCAF", "SAEMG", "ACAPO-MG", "SINDHOSP", "APROLIB", "FENAMEV", "ACESB", "CEMIG SAUDE", "ABRACEM", "ANAPROLIE", "UNASP"],
    termosUso: {
      dialog: false,
      check: false,
      jaVisualizado: false,
      callback: "",
    },
    loadingGlobal: false,
  },
  getters: {
    getLoadingInit(state) {
      return state.loadingInit;
    },
    getColor: (state) => (prop) => prop && state.whitelabelConfig.colors && state.whitelabelConfig.colors[prop] ? state.whitelabelConfig.colors[prop] : "",
    getAppName(state) {
      return state.whitelabelConfig.appName;
    },
    getLogoClaro(state) {
      return state.whitelabelConfig.logoClaro;
    },
    getLogoEscuro(state) {
      return state.whitelabelConfig.logoEscuro;
    },
    getLogoComTexto(state) {
      return state.whitelabelConfig.logoComTexto;
    },
    getWelcome(state) {
      return state.whitelabelConfig.welcome;
    },
    getFiliacaoEntidadesPermitidas(state) {
      return state.filiacaoEntidadesPermitidas;
    },
    getTermoUso(state) {
      return state.termosUso;
    },
    getCallbackTermo(state) {
      return state.termosUso.callback;
    },
    getLoadingGlobal(state) {
      return state.loadingGlobal;
    },
  },
  mutations: {
    SET_BRAND(state, payload) {
      state.whitelabelConfig = payload;
    },
    SET_LOADING_INIT(state, payload) {
      state.loadingInit = payload;
    },
    SET_TERMO_USO(state, payload) {
      state.termosUso = payload;
    },
    SET_DIALOG_TERMO(state, payload) {
      state.termosUso.dialog = payload;
    },
    SET_VISUALIZADO_TERMO(state, payload) {
      state.termosUso.jaVisualizado = payload;
    },
    SET_CHECK_TERMO(state, payload) {
      state.termosUso.check = payload;
    },
    SET_CALLBACK_TERMO(state, payload) {
      state.termosUso.callback = payload;
    },
    SET_LOADING_GLOBAL(state, payload) {
      state.loadingGlobal = payload;
    }
  },
});
